<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        <h2 class="headline mb-0">{{ $tc('title.fornecedor', 1) }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <metadados-container-layout
          v-if="metadadosFornecedor != null"
          :metadados-entidade="metadadosFornecedor"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="fornecedor"
          :formulario-filtros="false"
          :somenteLeitura="somenteLeitura"
          :novoCadastro="true"
          @MetadadosContainerLayout_abrirCadastro="abrirCadastro"
          @MetadadosContainerLayout_save="validarCadastro"
          @MetadadosContainerLayout_form_Invalido="validarCadastro"
          ref="container">
          <v-col v-if="indAmbienteMagalu" cols="12" sm="12" slot="antes">
            <v-btn
              id="btn_buscar_fornecedor_gemco"
              :disabled="(!(!!fornecedor.idExterno) || !fornecedor.idExterno.length)
                && (!(!!fornecedor.desCnpj) || !fornecedor.desCnpj.length)
                || fornecedorGemcoEncontrado || somenteLeitura"
              @click.native="buscarFornecedorGemco">
                {{ $t('label.buscar') }}
            </v-btn>
            <v-btn
              id="btn_limpar_busca"
              class="ml-3"
              :disabled="somenteLeitura || isEdicao"
              @click.native="limparBusca">
                {{ $t('label.limpar') }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              id="campo_codigo"
              name="campo_codigo"
              v-model="fornecedor.idExterno"
              :label="`${$tc('label.cod_fornecedor', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura || isEdicao || (indAmbienteMagalu && fornecedorGemcoEncontrado)"
              :counter="10"
              maxlength="10"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              id="campo_razao_social"
              name="campo_razao_social"
              v-model="fornecedor.desRazaoSocial"
              :label="`${$tc('label.razao_social', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura || !permiteCadastrarNovoFornecedor"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              id="campo_nome_fantasia"
              name="campo_nome_fantasia"
              v-model="fornecedor.desNomeFantasia"
              :label="`${$tc('label.nome_fantasia', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura || !permiteCadastrarNovoFornecedor"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_cnpj"
              name="campo_cnpj"
              v-model="fornecedor.desCnpj"
              :label="`${$tc('label.cnpj', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura || isEdicao || (indAmbienteMagalu && fornecedorGemcoEncontrado)"
              :maxlength="18"
              v-mask="'##.###.###/####-##'"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_ie"
              name="campo_ie"
              v-model="fornecedor.inscricaoEstadual"
              :label="`${$tc('label.inscricao_estadual', 1)}`"
              :disabled="somenteLeitura || !permiteCadastrarNovoFornecedor"
              :counter="50"
              maxlength="50"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_prazo_vencimento_contrato"
              name="campo_prazo_vencimento_contrato"
              v-model="fornecedor.prazoVencimentoContrato"
              :label="`${$tc('label.prazo_vencimento_contrato_dias', 1)} *`"
              :rules="[rules.required, rules.numeroValido]"
              :disabled="somenteLeitura"
              type="number"
              min="0"
              v-mask="'###'"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_prazo_vencimento_acao"
              name="campo_prazo_vencimento_acao"
              v-model="fornecedor.prazoVencimentoAcao"
              :label="`${$tc('label.prazo_vencimento_acao_dias', 1)} *`"
              :rules="[rules.required, rules.numeroValido]"
              :disabled="somenteLeitura"
              type="number"
              min="0"
              v-mask="'###'"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-select
              id="tipo_pagamento" name="tipo_pagamento"
              :items="tiposPagamento"
              v-model="fornecedor.idTipoPagamento"
              item-text="descricao"
              item-value="id"
              clearable
              :label="`${$tc('label.tipo_pagamento', 1)}`"
              :disabled="somenteLeitura"
              :no-data-text="$tc('message.nenhum_registro', 1)">
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-switch
              color="primary"
              :label="getlabelPagador()"
              v-model="fornecedor.indPagador"
              :disabled="somenteLeitura"
              class="mt-3">
            </v-switch>
          </v-col>
          <v-col cols="12" slot="antes">
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  id="campo_contato"
                  name="campo_contato"
                  v-model="fornecedor.contato.nomContato"
                  :label="`${$tc('label.contato', 1)}`"
                  :disabled="somenteLeitura"
                  :counter="100"
                  maxlength="100"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  id="campo_contato"
                  name="campo_contato"
                  v-model="fornecedor.contato.vlrEmail"
                  :label="`${$tc('label.email', 1)}`"
                  :disabled="somenteLeitura"
                  :counter="100"
                  maxlength="100"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  id="campo_contato"
                  name="campo_contato"
                  v-model="fornecedor.contato.vlrTelefone"
                  :label="`${$tc('label.telefone', 1)}`"
                  :disabled="somenteLeitura"
                  return-masked-value
                  v-mask="'(##) #####-####'"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" slot="depois">
            <endereco
              ref="endereco"
              @ENDERECO_valido="enderecoValido"
              @ENDERECO_invalido="enderecoInvalido"
              :somenteLeitura="somenteLeitura"
              :id="fornecedor.idEndereco"
              :required="false"
              >
            </endereco>
          </v-col>
          <v-col cols="12" slot="depois">
            <v-row>
              <v-col cols="12" md="4">
                <v-autocomplete
                  id="produto_fornecedor"
                  name="produto_fornecedor"
                  v-model="filtroNomeProduto"
                  :filter="filtroProduto"
                  :items="listaProdutos"
                  :search-input.sync="buscaListaProduto"
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  item-text="nomProduto"
                  item-value="idExterno"
                  :label="`${$tc('label.produto', 1)}`"
                  :placeholder="$tc('label.digite_para_buscar', 1)"
                  @change="setProduto"
                  :item-disabled="validaListaProduto"
                  clearable
                  :disabled="somenteLeitura"
                  return-object>
                    <template slot="item" slot-scope="data">
                      {{ data.item.idExterno }} - {{ data.item.nomProduto }}
                    </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="6" slot="depois">
            <v-data-table id="produto_fornecedor_tabela"
              :headers="headersProduto"
              :items="produtosPaginados"
              hide-default-footer
              disable-pagination
              :items-per-page="-1"
              :no-data-text="$t('message.nenhum_registro')">
              <template v-slot:item.acoes="{ item }" v-if="!somenteLeitura">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="removerProdutoPeloId(item.id)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('label.remover') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-pagination
              v-if="pagination.totalPage >= pagination.rowsPerPage"
              v-model="pagination.page"
              :length="pages"
              :total-visible="$vuetify.breakpoint.mdAndUp ? 15 : 7"
              class="py-2"
            ></v-pagination>
          </v-col>
        </metadados-container-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="somenteLeitura" id="btn_cancelar_novo_fornecedor" @click.native="openCancelarDialog">{{ $t('label.cancelar') }}</v-btn>
        <v-btn v-if="!somenteLeitura" id="btn_cancelar_novo_fornecedor" @click.native="openCancelarDialog">{{ $t('label.cancelar') }}</v-btn>
        <v-btn v-if="!somenteLeitura" id="btn_salvar_novo_fornecedor" @click.native="validarCadastro" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>

      <v-dialog v-model="dialogCadastroExtensao" persistent max-width="800px">
        <fornecedor-varejo-nova-extensao
          ref="fornecedorNovaExtensao"
          :somenteLeitura="somenteLeitura"
          :objeto="novaExtensao"
          @cancelar="fecharCadastroExtensao"
          @atualizarGrid="setarCadastroExtensao"
        />
      </v-dialog>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', { text: this.$tc('title.fornecedor', 1).toLowerCase() })"
      @agree="salvar">
    </confirm>

    <fornecedor-varejo-justificativa
      ref="dialogJustificativa"
      :ind-habilita-tabela-fornecedor="indHabilitaTabelaFornecedor"
      :ind-habilita-justificativa="indHabilitaJustificativa"
      :grupo-atual="grupoAtual"
      :grupo-novo="grupoNovo"
      :fornecedor-dados-requisicao="fornecedorDadosRequisicao"
      @agree="salvarJustificativa">
    </fornecedor-varejo-justificativa>

    <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="$t('message.tem_certeza_cancelar')"
      @agree="cancelar">
    </confirm>

    <confirm
      ref="okDialog" :title="$t('title.atencao')"
      @agree="cancelar">
    </confirm>
  </v-form>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { copyObject } from '../../../common/functions/helpers';
import { skipLoading } from '../../../common/functions/loading';
import { removerPontosETracos } from '../../../common/functions/string-utils';
import MetadadosContainerLayout from '../../../shared-components/metadados/MetadadosContainerLayout';
import FornecedorVarejoNovaExtensao from './FornecedorVarejoNovaExtensao';
import Fornecedor from '../Fornecedor';
import Endereco from '../../endereco/Endereco';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import FornecedorVarejoJustificativa from '../FornecedorVarejoJustificativa';
import { generateComputed } from '../../../common/functions/roles-computed-generator';

export default {
  name: 'FornecedorVarejoForm',
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MetadadosContainerLayout,
    FornecedorVarejoNovaExtensao,
    Endereco,
    Confirm,
    FornecedorVarejoJustificativa,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      fornecedorResource: this.$api.fornecedor(this.$resource),
      fornecedorIntegracaoResource: this.$api.fornecedorIntegracao(this.$resource),
      produtoResource: this.$api.produto(this.$resource),
      tipoBeneficioResources: this.$api.tipoBeneficio(this.$resource),
      buscaListaProduto: null,
      grupoAtual: {},
      grupoNovo: {},
      listaProdutos: [],
      grupoFornecedorAtual: [],
      pagination: {
        page: 1,
        totalPage: 0,
        rowsPerPage: 10,
      },
      filtroNomeProduto: '',
      nomeDeParaGrupoFornecedor: '',
      headersProduto: [
        {
          text: '', value: 'acoes', width: '2%', sortable: false,
        },
        { text: this.$tc('label.cod_produto', 1), width: '30%', value: 'idExterno' },
        { text: this.$tc('label.nome_produto', 1), width: '60%', value: 'nomProduto' },
      ],

      fornecedor: new Fornecedor(),
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        numeroValido: (value) => {
          const msg = this.$t('message.numero_invalido');
          return (!!value && !Number.isNaN(value.toString()) && Number(value) >= 0) || msg;
        },
      },
      dialogCadastroExtensao: false,
      novaExtensao: {},
      fornecedorDadosRequisicao: {},
      tiposPagamento: [],
      buscaGemcoRealizada: false,
      fornecedorGemcoEncontrado: false,
      indHabilitaJustificativa: false,
      indHabilitaTabelaFornecedor: false,
      indPrimeiroCarregamentoEndereco: true,
      fornecedorCopy: new Fornecedor(),
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getFornecedorMetadado',
    ]),
    ...generateComputed('FORN', [
      'canAccessCRUD',
    ]),
    ...mapGetters('implantacao', [
      'indAmbienteProdutivo',
      'chaveEmpresa',
    ]),
    indAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    indAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    metadadosFornecedor() {
      if (this.getFornecedorMetadado && this.getFornecedorMetadado.mapaEntidades) {
        return this.getFornecedorMetadado;
      }
      return undefined;
    },
    isEdicao() {
      return this.fornecedor.id != null;
    },
    permiteCadastrarNovoFornecedor() {
      return (!this.isEdicao && this.buscaGemcoRealizada && !this.fornecedorGemcoEncontrado)
        || (!this.indAmbienteMagalu);
    },
    pages() {
      return Math.ceil(this.pagination.totalPage / this.pagination.rowsPerPage);
    },
    produtosPaginados() {
      const listaPaginada = copyObject(this.fornecedor.listaprodutos);
      const startFrom = (this.pagination.page * this.pagination.rowsPerPage) - this.pagination.rowsPerPage;
      return listaPaginada.splice(startFrom, this.pagination.rowsPerPage);
    },
  },
  watch: {
    buscaListaProduto(val) {
      if (this.timeoutProduto) {
        window.clearTimeout(this.timeoutProduto);
      }
      this.timeoutProduto = window.setTimeout(() => {
        this.buscarProdutoAutocomplete(val);
      }, 500);
    },
    fornecedor: {
      handler() {
        if (this.fornecedor.listaprodutos) {
          this.pagination.totalPage = this.fornecedor.listaprodutos.length;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setFornecedorMetadado',
    ]),
    carregarFornecedor() {
      const { id } = this.$route.params;
      skipLoading();
      this.fornecedorResource.buscar({ id })
        .then((res) => {
          this.fornecedor = { ...this.fornecedor, ...res.body };
          this.fornecedorCopy = { ...this.fornecedor, ...res.body };
          if (this.fornecedor.grupofornecedor) {
            this.grupoFornecedorAtual = this.fornecedor.grupofornecedor;
            this.fornecedorDadosRequisicao.cnpj = this.fornecedor.desCnpj;
            this.fornecedorDadosRequisicao.desRazaoSocial = this.fornecedor.desRazaoSocial;
            this.fornecedorDadosRequisicao.idFornecedor = this.fornecedor.id;
            this.fornecedorDadosRequisicao.desRazaoSocial = this.fornecedor.desRazaoSocial;
            this.fornecedorDadosRequisicao.idExterno = this.fornecedor.idExterno;
            this.fornecedorDadosRequisicao.idGrupoFornecedor = this.grupoFornecedorAtual.id;
          }
          this.buscarProdutoAutocomplete();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificaAlteracaoCampos() {
      if (this.isEdicao) {
        this.indHabilitaJustificativa = true;
      }
    },
    validarCadastro() {
      if (this.isCnpjValido()) {
        this.$refs.endereco.isValido(this.$refs.form.validate());
      }
    },
    validaListaProduto(item) {
      return this.fornecedor.listaprodutos.find((e) => e.idExterno === item.idExterno);
    },
    associacaoProdutoValido() {
      if (this.fornecedor.listaprodutos.length) {
        return true;
      }
      return false;
    },
    validarEndereco() {
      this.$refs.endereco.isValido(true);
    },
    isCnpjValido() {
      if (this.fornecedor.desCnpj.replace(/\D+/g, '').length !== 14) {
        this.$toast(this.$t('message.cnpj_tamanho'));
        return false;
      }
      return true;
    },
    enderecoValido(endereco) {
      this.fornecedor.endereco = endereco;
      if (this.indPrimeiroCarregamentoEndereco) {
        this.fornecedorCopy.endereco = endereco;
        this.indPrimeiroCarregamentoEndereco = false;
      }
      this.openSaveDialog();
    },
    enderecoInvalido(endereco) {
      this.fornecedor.endereco = endereco;
    },
    filtroProduto(item, queryText, itemText) {
      const codProduto = item.idExterno;
      const searchText = queryText.toLowerCase();
      const itemTextLower = itemText.toLowerCase();

      return codProduto.indexOf(searchText) > -1
        || itemTextLower.indexOf(searchText) > -1;
    },
    buscarProdutoAutocomplete(busca = null) {
      const param = {
        autocomplete: busca,
        limit: 30,
        page: 0,
      };

      this.produtoResource.selecao(param).then((response) => {
        this.listaProdutos = Object.assign([], response.data);
      }, (err) => {
        this.$error(this, err);
      });
    },
    removerProdutoPeloId(id) {
      this.fornecedor.listaprodutos = this.fornecedor
        .listaprodutos.filter((div) => div.id !== parseInt(id, 10));
      this.buscarProdutoAutocomplete();
      this.$forceUpdate();
    },
    setProduto(hit) {
      if (!hit) return;
      window.setTimeout(() => {
        this.filtroNomeProduto = '';
      });
      this.fornecedor.listaprodutos.push(hit);
      this.buscarProdutoAutocomplete();
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      if (this.isEdicao) {
        this.indHabilitaJustificativa = !this.verificaCamposAlterados();
      }
      const grupofornecedorSelecionado = this.fornecedor.grupofornecedor;
      if (this.grupoFornecedorAtual.id && this.grupoFornecedorAtual.id !== grupofornecedorSelecionado.id) {
        this.grupoAtual = this.grupoFornecedorAtual;
        this.grupoNovo = grupofornecedorSelecionado;
        this.indHabilitaTabelaFornecedor = true;
        this.$refs.dialogJustificativa.openTabelaFornecedor();
      } else if (this.indHabilitaJustificativa && this.grupoFornecedorAtual.id === grupofornecedorSelecionado.id) {
        this.indHabilitaTabelaFornecedor = false;
        this.$refs.dialogJustificativa.open();
      } else {
        this.$refs.confirmDialog.open();
      }
    },
    verificaCamposAlterados() {
      return this.fornecedorCopy.desCnpj === this.fornecedor.desCnpj
      && this.fornecedorCopy.desNomeFantasia === this.fornecedor.desNomeFantasia
      && this.fornecedorCopy.desRazaoSocial === this.fornecedor.desRazaoSocial
      && this.fornecedorCopy.grupofornecedor.id === this.fornecedor.grupofornecedor.id
      && this.fornecedorCopy.id === this.fornecedor.id
      && this.fornecedorCopy.endereco.idCidade === this.fornecedor.endereco.idCidade
      && this.fornecedorCopy.endereco.nomCep === this.fornecedor.endereco.nomCep
      && this.fornecedorCopy.endereco.nomBairro === this.fornecedor.endereco.nomBairro
      && this.fornecedorCopy.endereco.nomEndereco === this.fornecedor.endereco.nomEndereco
      && this.fornecedorCopy.idEndereco === this.fornecedor.idEndereco
      && this.fornecedorCopy.idExterno === this.fornecedor.idExterno
      && (this.fornecedorCopy.idTipoPagamento === this.fornecedor.idTipoPagamento
      || (this.fornecedorCopy.idTipoPagamento === this.fornecedor.idTipoPagamento && this.fornecedor.idTipoPagamento !== null))
      && this.fornecedorCopy.inscricaoEstadual === this.fornecedor.inscricaoEstadual
      && this.fornecedorCopy.listaprodutos.length === this.fornecedor.listaprodutos.length
      && this.fornecedorCopy.nom_contato === this.fornecedor.contato.nomContato
      && this.formatNumber(this.fornecedorCopy.prazoVencimentoAcao) === this.formatNumber(this.fornecedor.prazoVencimentoAcao)
      && this.formatNumber(this.fornecedorCopy.prazoVencimentoContrato) === this.formatNumber(this.fornecedor.prazoVencimentoContrato)
      && this.fornecedorCopy.vlr_email === this.fornecedor.contato.vlrEmail
      && this.fornecedorCopy.vlr_telefone === this.fornecedor.contato.vlrTelefone;
    },
    formatNumber(item) {
      return (item || '').toString().replace('.', ',');
    },
    buscarTabelaFornecedor() {
      const { idFornecedor } = this.fornecedorDadosRequisicao;
      this.fornecedorResource.buscarApuracoesImpactadas({ idFornecedor }).then((res) => {
        this.listaApuracoesImpactadas = res.data;
      });
    },
    openCancelarDialog() {
      this.$refs.cancelarDialog.open();
    },
    openOkDialog() {
      this.$refs.okDialog.open();
    },
    salvarJustificativa(justificativa, indAlteracaoGrupo, idsApuracao) {
      this.fornecedor.justificativaAlteracaoCampos = justificativa;
      if (indAlteracaoGrupo) {
        this.fornecedor.indAlteracaoGrupoFornecedor = indAlteracaoGrupo;
        this.fornecedor.apuracoesImpactadasAlteracaoGrupo = idsApuracao;
      }
      this.salvar();
    },
    salvar() {
      this.fornecedor.idsProdutos = [];
      this.fornecedor.listaprodutos.forEach((value) => {
        this.fornecedor.idsProdutos.push(value.id);
      });

      const objArmazenamento = {
        ...this.fornecedor,
        ...this.$refs.container.getValoresDependencias(),
      };

      this.exibeAlertaLgpd();
      if (this.fornecedor.id) {
        this.atualizar(objArmazenamento);
      } else {
        this.inserir(objArmazenamento);
      }
    },
    exibeAlertaLgpd() {
      if (this.indAmbienteProdutivo) return;

      const alertaLgpd = this.$t('message.lgpd');
      alert(alertaLgpd); // eslint-disable-line
    },
    inserir(obj) {
      this.fornecedorResource.gravar(obj)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.$router.push({ name: this.$route.params.from || 'fornecedor' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    atualizar(obj) {
      const { id } = obj;
      this.fornecedorResource.atualizar({ id }, obj)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
          this.$router.push({ name: this.$route.params.from || 'fornecedor' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelar() {
      this.$router.push({ name: this.$route.params.from || 'fornecedor' });
    },
    abrirCadastro(metadados) {
      this.novaExtensao = {};
      this.novaExtensao.indAtivo = true;
      this.novaExtensao.idNivelExtensao = metadados.idNivelExtensao;
      this.novaExtensao.metadados = metadados;
      this.dialogCadastroExtensao = true;
      setTimeout(() => {
        this.$refs.fornecedorNovaExtensao.setNivel(metadados.idNivelExtensao);
      }, 3E2);
    },
    fecharCadastroExtensao() {
      this.dialogCadastroExtensao = false;
    },
    setarCadastroExtensao(obj, metadados) {
      if (metadados.manyToMany) {
        if (!this.fornecedor[metadados.label]) {
          this.fornecedor[metadados.label] = [];
        }
        this.fornecedor[metadados.label].push(obj);
      } else {
        this.fornecedor[metadados.label] = obj;
      }
      this.$refs.container.refresh();
      this.dialogCadastroExtensao = false;
    },
    buscarTiposBeneficios() {
      this.tipoBeneficioResources.listarAtivos().then((response) => {
        this.tiposPagamento = response.data;
      });
    },
    buscarFornecedorGemco() {
      const params = {
        idExterno: this.fornecedor.idExterno ? this.fornecedor.idExterno : null,
        desCnpj: this.fornecedor.desCnpj ? removerPontosETracos(this.fornecedor.desCnpj) : null,
      };

      this.fornecedorIntegracaoResource.buscarFornecedorGemco(params)
        .then((res) => {
          if (res.data && res.data.records.length && res.data.records[0].suppliercode) {
            const fornecedorGemco = res.data.records[0];
            this.fornecedor = { ...this.fornecedor, ...this.getFornecedorGemcoFormatado(fornecedorGemco) };

            this.fornecedor.prazoVencimentoContrato = 30;
            this.fornecedor.prazoVencimentoAcao = 30;

            if (fornecedorGemco.addresszipcode && (!this.isEdicao || this.$refs.endereco.getCep() === null
              || removerPontosETracos(fornecedorGemco.addresszipcode) !== removerPontosETracos(this.$refs.endereco.getCep()))) {
              this.$refs.endereco.setarCep(fornecedorGemco.addresszipcode, fornecedorGemco.addressnumber);
            }

            this.fornecedorGemcoEncontrado = true;
          } else {
            this.$toast(this.$t('message.fornecedor_nao_encontrado_gemco'));
          }
          this.buscarProdutoAutocomplete();
          this.buscaGemcoRealizada = true;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    limparBusca() {
      this.fornecedor = new Fornecedor();
      this.$refs.endereco.cleanEndereco();
      this.fornecedorGemcoEncontrado = false;
      this.buscaGemcoRealizada = false;
    },
    getFornecedorGemcoFormatado(fornecedorGemco) {
      const fornecedorFormatado = {
        idExterno: fornecedorGemco.suppliercode,
        desNomeFantasia: fornecedorGemco.fantasyname,
        desRazaoSocial: fornecedorGemco.corporatename,
        desCnpj: String(fornecedorGemco.cnpjcpf).padStart(14, '0'),
        inscricaoEstadual: fornecedorGemco.stateregistration,
        contato: {
          nomContato: fornecedorGemco.contactname,
          vlrEmail: fornecedorGemco.contactemail,
        },
      };

      return fornecedorFormatado;
    },
    getlabelPagador() {
      return this.indAmbienteEpoca ? this.$tc('label.nota_debito', 1) : this.$t('label.fornecedor_pagador');
    },
  },
  beforeMount() {
    this.buscarTiposBeneficios();
    if (this.$route.params.id) {
      if (this.getFornecedorMetadado && this.getFornecedorMetadado.mapaEntidades) {
        this.carregarFornecedor();
      } else {
        this.setFornecedorMetadado({
          resource: this.metadadoResource.listarFornecedor,
          params: null,
          callback: this.carregarFornecedor,
        });
      }
    }
  },
  mounted() {
    this.buscarProdutoAutocomplete();
    this.somenteLeitura = !this.canAccessCRUD;
  },
};
</script>
