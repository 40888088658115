export default class Fornecedor {
  constructor(data = null) {
    if (data) {
      this.id = data.id;
      this.desCnpj = data.desCnpj;
      this.idContato = data.idContato;
      this.indAtivo = data.indAtivo;
      this.listaprodutos = data.listaprodutos;
      if (data.contato != null) {
        this.contato = data.contato;
      }
      if (data.endereco != null) {
        this.endereco = data.endereco;
      }
    } else {
      this.listaprodutos = [];
      this.indAtivo = true;
      this.contato = {
        nomContato: '',
        vlrTelefone: '',
        vlrEmail: '',
      };
      this.endereco = {
        nomEndereco: null,
        nomBairro: null,
        nomeCidade: null,
        nomUf: null,
        complemento: null,
        numNumero: null,
        idCidade: null,
        idEstado: null,
      };
    }
  }

  construirAPartirDaConsultaCompleta(dadosDaConsulta) {
    this.id = dadosDaConsulta.id;
    this.desCnpj = dadosDaConsulta.desCnpj;
    this.idContato = dadosDaConsulta.idContato;
    this.indAtivo = dadosDaConsulta.indAtivo;
    this.listaprodutos = dadosDaConsulta.listaprodutos;
    this.contato = {
      nomContato: dadosDaConsulta.nomContato,
      vlrTelefone: dadosDaConsulta.vlrTelefone,
      vlrEmail: dadosDaConsulta.vlrEmail,
    };
    this.endereco = {
      nomEndereco: dadosDaConsulta.nomEndereco,
      nomBairro: dadosDaConsulta.nomBairro,
      nomeCidade: dadosDaConsulta.nomeCidade,
      nomUf: dadosDaConsulta.nomUf,
      complemento: dadosDaConsulta.complemento,
      numNumero: dadosDaConsulta.numNumero,
      idCidade: dadosDaConsulta.idCidade,
      idEstado: dadosDaConsulta.idEstado,
    };
  }

  ehEdicao() {
    return this.id;
  }
}
