<template>
  <div>
    <fornecedor-varejo-form />
  </div>
</template>

<script type="text/javascript">

import FornecedorVarejoForm from './varejo/FornecedorVarejoForm';

export default {
  name: 'FornecedorForm',
  components: {
    FornecedorVarejoForm,
  },
  computed: {
  },
};
</script>
